import React, { useState , useEffect} from 'react';


import '../css/topbar.css';
import Logo from '../images/logo.png';
import Naked from '../images/naked.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faInfo, faDashboard, faBookBookmark, faCalendar,
     faPuzzlePiece , faShieldAlt, faDiceOne, faBahtSign, faChartBar, faTools
    , faBell, faChevronDown, faArrowUp} from '@fortawesome/free-solid-svg-icons';
import HomeImg from '../images/homebody.png';
import { Link, useNavigate } from 'react-router-dom'

import IdSideBar from '../components/sidebar';

function IdLayout(props) {
    const { profile } = props;
    const [staySigned, setStaySigned] = useState(false);
    const [toggle, setToggle] = useState(false);
    const navigate = useNavigate();
console.log('profile in _layout');
console.log(profile);

    const makepayment=()=>{
      navigate('/makepayment')
    }

    const onToggle=()=>{
      setToggle(!toggle);
    }
  
    const onLogout = ()=>{
      //remove all the login details
      setToggle(false);
      localStorage.removeItem("profile")
             localStorage.removeItem("token");
             localStorage.removeItem("tokendate");
        navigate('/login');
    }

  return (
    <div className='overall'>
      
    
     <IdSideBar active={props.active} profile={profile}  />
      <div className='mainbody'>
         <div className='topbar no-print'>
     
            <div className='name'><span>Hi {profile?.firstName}</span></div>
            <div style={{display:'flex', flex:1}}><input placeholder='search' style={{width:'80%', marginBottom:'auto', marginTop:'auto'}}/></div>
            <div style={{display:'flex', flexDirection:'row', justifyContent:'center'}}>
                <div style={{fontSize:24, marginBottom:'auto', marginTop:'auto',  marginLeft:8, marginRight:8}}>
            <FontAwesomeIcon icon={faBell} />
            </div>
            <div className='profile' >
                <div className='avatar'>
                    <img src={Naked} />
                </div>
                <div style={{fontWeight:600,marginBottom:'auto', marginTop:'auto', marginLeft:8, marginRight:8}}>
                    <span>{profile?.firstName} {profile?.lastName}</span>
                    <div className='dropdown' style={{marginLeft:8, marginRight:8}}>
                      <FontAwesomeIcon icon={faChevronDown} onClick={onToggle} />
                      <div className= {`dropdown-content ${toggle?'show':''}`}> 
                         <ul className='clearlist'>
                          <li><Link to="/settings"> Wallet Details</Link></li>
                          <li><button className='redbutton' style={{marginTop:'auto', marginBottom:'auto'}} onClick={onLogout}>Logout</button></li>
                         </ul>
                      </div>
                    </div>
                    
                </div>
            </div>
            </div>
          
            {(profile?.parentId==null && (profile?.userType=="Customer" || profile?.userType=="Com-Owner")) ?
             <button className='greenbutton' style={{marginTop:'auto', marginBottom:'auto'}} onClick={makepayment}>Fund Wallet</button>:<button className='greenbutton' style={{marginTop:'auto', marginBottom:'auto'}} > As {profile?.userType}</button>}  
          
      </div>
        
        <div className='maincontent'>

        {props.children} 
        </div>
      </div>
    </div>
  )
}

export default IdLayout