import React, { useState , useEffect, useContext} from 'react';


import '../css/topbar.css';
import Logo from '../images/logo.png';
import Naked from '../images/naked.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faInfo, faDashboard, faBookBookmark, faCalendar,
     faPuzzlePiece , faShieldAlt, faDiceOne, faBahtSign, faChartBar, faTools
    , faBell, faChevronDown, faArrowUp} from '@fortawesome/free-solid-svg-icons';
import HomeImg from '../images/homebody.png';
import { Link, useNavigate } from 'react-router-dom'
import { ShareContext } from '../context/sharecontext';
import * as Constants from '../Constants';
import LoadingImg from '../images/loading.gif';

import IdLayout from '../components/_layout';

function Dashboard() {
    const { profile, token, setProfile } = useContext(ShareContext);
    const [staySigned, setStaySigned] = useState(false)
    //load profile info.
    const [user, setUser] =useState({});
    const [loading, setLoading] =useState(false);
    const [activities, setActivities]=useState([]);

    useEffect(() => {
     
       Load();
       LoadReports();
         }, []);
    
    const Load=async()=>{
      
        var urls=`${Constants.rooturl}/customer/${profile.id}`;
        try {
            setLoading(true);
            const resp = await fetch(urls, { 
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': token,
                
              },
            });
            
            const data = await resp.json();
          
            setLoading(false);
            console.log(data);
            if (data.code === 0) {
                
                setProfile({...profile, unitBalance:data.res.unitBalance} );
            }else {    
              console.error(data.status);
            }
          } catch (error) {
            setLoading(false);
            console.error(error); 
          }
     }
     const LoadReports=async()=>{
      
      var urls=`${Constants.rooturl}/customer/activities/${profile.id}`;
      try {
          setLoading(true);
          const resp = await fetch(urls, { 
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': token,
              
            },
          });
          
          const data = await resp.json();
        
          setLoading(false);
          console.log(data);
          if (data.code === 0) {
              
              //setProfile({...profile, unitBalance:data.res.unitBalance} );
              setActivities(data.reqcall);
          }else {    
            console.error(data.status);
          }
        } catch (error) {
          setLoading(false);
          console.error(error); 
        }
   }

  return (
   <IdLayout active="dashboard" profile={profile}>
    <div>
      {loading && <div>
        <img src={LoadingImg} style={{width:300, marginLeft:'auto', marginRight:'auto'}} />
        </div>}  
    <div className='cards'>
                <div className='card'>
                    <div className='topcard'>
                        <span>Wallet</span>
                        <div className='perc'><FontAwesomeIcon icon={faArrowUp} /> <span>10%</span>   <FontAwesomeIcon icon={faArrowUp} /></div>
                    </div>
                    <p class="unit">{profile.unitBalance} units</p>
                    <p class="subunit">Wallet balance {profile.unitBalance} units</p>
                </div>
                <div className='card'>
                    <div className='topcard'>
                        <span>Total Activity</span>
                        <div className='perc'><FontAwesomeIcon icon={faArrowUp} /> <span>10%</span>   <FontAwesomeIcon icon={faArrowUp} /></div>
                    </div>
                    <p class="unit">0 units</p>
                    <p class="subunit">Total activity 0 units</p>
                </div>

            </div>
            <div style={{display:'flex'}}>
                <div style={{flex:1,}}>
                     <div style={{height:369, backgroundColor:'white', width:'100%'}}>
                        <img src={Logo} />
                        </div>
                     {/*    <div>Recent Activities</div> */}
               
                </div>
               
                <div style={{width:276}}>
                  <h2>Search History</h2>
                  {activities?.map((act, idx) =>{return (
                    <div key={idx} className='place-copy'>
                      <div>
                        <span style={{fontSize:18, textTransform:'capitalize'}}>{act.type}</span><div>{act.params}</div>
                        <div>{act.unitUsed}units</div>
                      </div>
                    
                    <div style={{display:'flex', flexDirection:'row'}}>
                      <div>{Constants.formatDate(act.createDate) }</div>
                      
                     
                    </div>
                    </div>
                  )})}
                  <Link to="/reports">See more</Link>
                </div>
            </div>
    </div>
   </IdLayout>
  )
}

export default Dashboard