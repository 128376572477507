import React, { useState , useEffect, useContext} from 'react';


import '../css/topbar.css';
import Logo from '../images/logo.png';
import Naked from '../images/naked.png';
import LoadingImg from '../images/loading.gif';
import FingerImg from '../images/finger.jpg'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faInfo, faDashboard, faBookBookmark, faCalendar,
     faPuzzlePiece , faShieldAlt, faDiceOne, faBahtSign, faChartBar, faTools
    , faBell, faChevronDown, faArrowUp} from '@fortawesome/free-solid-svg-icons';
import HomeImg from '../images/homebody.png';
import { Link, useNavigate, useParams } from 'react-router-dom'
import { ShareContext } from '../context/sharecontext';
import * as Constants from '../Constants';

import IdLayout from '../components/_layout';
import IdInput from '../components/idInput';
import IdSelect from '../components/idSelect';

function Search() {

    const navigate = useNavigate();
    const { profile , setRaw, activeType, setActiveType, token} = useContext(ShareContext);
    const [staySigned, setStaySigned] = useState(false)
    const {type} = useParams(); 
    const [label, setLabel]=useState('');
    const [value, setValue]=useState('');
    const [note, setNote] = useState('');
    const [title, setTitle]= useState('');
    const [loading, setLoading] =useState(false);
    const [pass,setPass]=useState({firstname:'', lastname:'', dateofbirth:''});
    const [demo, setDemo]=useState({lastname:'', seldate:'',gender:''})
    const [fin, setFin]=useState({pos:''});
    const [cac, setCac] = useState({});
    const [err, setErr] =useState('');

    const genders=[{value:'male',text:'Male'}, {value:'female',text:'Female'}]
    const finpos=[{value:'Right Thumb',text:'Right Thumb'},{value:'Right Index',text:'Right Index Finger'},{value:'Right Middle',text:'Right Middle Finger'}, {value:'Right Ring',text:'Right Ring Finger'},{value:'Right Little',text:'Right Little Finger'},
      {value:'Left Thumb',text:'Left Thumb'},{value:'Left Index',text:'Left Index Finger'},{value:'Left Middle',text:'Left Middle Finger'}, {value:'Left Ring',text:'Left Ring Finger'},{value:'Left Little',text:'Left Little Finger'}
    ]

    useEffect(() => {
      setErr('');
      setActiveType(type);
      if(type=='nin-nin'){
        setLabel('Enter your NIN Number')
        setNote('Note: Enter the 11 NIN numbers')
        setTitle('Verify NIN Number')
      }else if(type=='nin-phone'){
        setLabel('Enter Phone Number');
        setNote('Note: Enter the 11 digit numbers')
        setTitle('Verify NIN by Phone')
      }
      else if(type=='nin-doc'){
        setLabel('Document Number');
        setTitle('Verify Document No');
        setNote('');
      }
      else if(type=='nin-fin'){
        setLabel('Finger Print');  
        setNote('');
      }else if(type=='nin-demo'){
        setTitle('Verify Demographic Data')
        setLabel('First Name');  
        setNote('');
      }else if(type=='nin-bvn'){
        setTitle('Retrieve NIN');
        setLabel('Enter Bank Verification Number');
        setNote('');
      }else if(type=='nin-vir'){
        setTitle('Verify  vNIN');
        setLabel('Enter Virtual NIN');
        setNote('Note: This service cost 2units.')
      }
      else if(type=='bvn'){
        setLabel('BVN');
        setNote('Enter the 11 digit numbers')
        setTitle('BVN')
      }
      else if(type=='tin'){
        setLabel('TIN');
        setNote('Enter the 11 digit numbers')
        setTitle('TIN')
      }else if(type=='passport'){
        setLabel('Nigerian Passport Number');
        setNote('')
        setTitle('Nigeria Passport')
      }else if(type=='cac'){
        setLabel('Company Name');
        setNote('')
        setTitle('Verify CAC')
      }
      else if(type=='driver'){
        setLabel('Drivers License Number');
        setNote('')
        setTitle('Verify Drivers License')
      }else if(type=='voter'){
        setLabel('Voters Registration Number');
        setNote('')
        setTitle('Verify Voters Card')
      }
       }, [type]);

       const onChange=(e)=>{
        const v=e.target.value;
        setValue(v);
       }

       const onSearch=async()=>{
        setLoading(true);
        
        try {
          var v={value:value};
          const resp = await fetch(`${Constants.rooturl}/verify/${type}`, {
            method: 'POST',
            body:JSON.stringify(v) ,
            headers: {
              'Content-Type': 'application/json, text/plain',
              'Authorization': token,
            },
          });
          
          const data = await resp.json();
          setLoading(false);
          console.log(data);
          if (data.code === 0) {
              //setGazettes(data.gazettes);
              if(type=='nin-vir'){
                var ddata=JSON.parse(data.data);
                setRaw({...ddata, photograph:data.photo} );
              }else{
                setRaw(data.res);
              }
              
              onMove();
          } else if(data.code==-1) {   
            //onMove();
            setErr(Constants.serverErr);
          }
          else if(data.code==-10){
            //expired. redirect to login
            navigate('/login')
          }
          else if(data.code==-3) {   
            //onMove();
            setErr('Insufficient balance. Fund your wallet');
          }else{
            setErr(data.status);
          }
        } catch (error) {
          setLoading(false);
          console.error(error);
          setErr(Constants.serverErr);
          //onMove();
        }

       }

       const onMove=()=>{
        setTimeout(() => {
          if(type=='bvn'){
            navigate('/searchresult/bvn')
          }else if(type=='cac'){
            navigate('/searchresult/cac')
          }else if(type=='tin'){
            navigate('/searchresult/tin')
          }else if(type=='passport'){
            navigate('/searchresult/passport')
          }else if(type=='voter'){
            navigate('/searchresult/voter')
          }else if(type=='driver'){
            navigate('/searchresult/license')
          }else if(type=='nin-vir'){
            navigate('/searchresult/nin-vir')
          }
          else{
            navigate('/ninsearch/result');
          }
          
        }, 800);
       }
    
  return (

   <IdLayout active={type} profile={profile}>
    <div>
            <div style={{display:'flex'}}>
                <div style={{flex:1,  display:'flex', flexDirection:'row', padding:10}}>
                     <div style={{height:369, flex:1, margin:'auto', display:'flex'}}>
                        <img src={Logo} style={{width:200, margin:'auto'}} />
                        </div>
                        <div style={{flex:1}}>
                          <h2>{title}</h2>
                         <div style={{display:'flex', flexDirection:'column', backgroundColor:'white',borderRadius:10, minHeight:540, width:'80%', maxWidth:448}}>
                              <div style={{flex:1}}>
                              <div style={{maxWidth:'80%'}}>
                              {type!='nin-fin' && <IdInput label={label} value={value} onChange={onChange} />}  

                                {type=='passport' && <>
                                  <IdInput label="First Name" value={pass.firstname} onChange={(e)=> setPass({...pass, firstname:e.target.value})} />
                                  <IdInput label="Surname" value={pass.lastname} onChange={(e)=> setPass({...pass, lastname:e.target.value})} />
                                  <IdInput label="Date Of Birth" value={pass.dateofbirth} type='date' onChange={(e)=> setPass({...pass, dateofbirth:e.target.value})} />
                                </>}

                                {type=='nin-demo' && <>
                                  <IdInput label="Last Name" value={demo.lastname} onChange={(e)=> setDemo({...demo, lastname:e.target.value})} />
                                  <IdInput label="Date" value={pass.seldate} type='date' onChange={(e)=> setDemo({...demo, seldate:e.target.value})} />
                                 <IdSelect onChange={(e)=> setDemo({...demo, gender:e.target.value})} disabledText="Select Gender" items={genders} label='Gender' />
                                </>}

                                {type=='nin-fin' && <>
                                 <IdSelect onChange={(e)=> setFin({...fin, finPos:e.target.value})} disabledText="Select Finger Position" items={finpos} label='Select Finger Position' />
                                 <IdInput label="Choose File" value={pass.seldate} type='file' onChange={(e)=> setFin({...fin, finfile:e.target.value})} />
                                 <img src={FingerImg} style={{width:200, marginLeft:'auto', marginRight:'auto'}} />
                                </>}
                                {type=='cac' && <>
                                  <IdInput label="CAC Reg Number" value={cac.regno} onChange={(e)=> setCac({...cac, regno:e.target.value})} />
                                  
                                </>}
                              </div>
                              <p style={{textAlign:'center',margin:27}}>{note}</p>
                              </div> 
                                {loading && <div  style={{display:'flex', justifyContent:'center', marginBottom:16, flexDirection:'column'}}>
                                  <img src={LoadingImg} style={{width:300, marginLeft:'auto', marginRight:'auto'}} />
                                  <span style={{ marginLeft:'auto', marginRight:'auto'}}>Loading...</span>
                                </div>}  
                            {!loading && <div style={{display:'flex', justifyContent:'center', marginBottom:16}}>
                                <button className='greenbutton' onClick={onSearch}>Search</button>

                              </div>}  
                              <div style={{fontSize:15, color:'red', textAlign:'center', padding:15, fontWeight:'bold'}}>{err}</div>
                         </div>
                          
                          
                        </div>
               
                </div>
               
               
            </div>
    </div>
   </IdLayout>
  )
}

export default Search