import React, { useState , useEffect, useContext} from 'react';
import '../css/sidebar.css';


import Logo from '../images/logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Link, useNavigate } from 'react-router-dom'
import { ShareContext } from '../context/sharecontext';



function IdHomeTop(props) {

    
    useEffect(() => {
      
     }, []);

     const navigate = useNavigate();
     const onLogin=()=>{
         navigate('/login');
     }
  return (
    <div className='header'>
            <img src={Logo}/>
            <div>
                <ul>
                    <li><a href='#'>Home</a> </li>
                    <li><a href='#'>About</a> </li>
                    <li><a href='#'>How its works</a></li>
                    <li><a href='#'>API</a></li>
                    <li><a href='#'>Contact Us</a></li>
                    <li><button className='greenbutton' onClick={onLogin}>Log In</button></li>
                </ul>
            </div>
       </div>
  )
}

export default IdHomeTop