import React, { useState } from 'react';


import Logo from '../images/logo.png';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faInfo, faCopyright, faPhone } from '@fortawesome/free-solid-svg-icons';
import HomeImg from '../images/homebody.png';

function IdSelect(props) {
    const [staySigned, setStaySigned] = useState(false)

  return (
    <div style={{marginTop:49, marginLeft:27, flex:1}}>
    <label style={{fontSize:18, fontFamily:'Inter', fontWeight:500}}>{props.label}</label>
    <div className='inputtext'>
     
      <select {...props}  >
      <option disabled>{props.disabledText}</option>
      {props.items.map((item, idx) =>{return (
        <option key={idx} value={item.value}>{item.text}</option>
      )})}
      </select>
    
    </div>
    
</div>
  )
}

export default IdSelect