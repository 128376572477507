import React, { useState , useEffect, useContext} from 'react';


import '../css/topbar.css';
import Logo from '../images/logo.png';
import Naked from '../images/naked.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faInfo, faDashboard, faBookBookmark, faCalendar,
     faPuzzlePiece , faShieldAlt, faDiceOne, faBahtSign, faChartBar, faTools
    , faBell, faChevronDown, faArrowUp, faSearch} from '@fortawesome/free-solid-svg-icons';
import HomeImg from '../images/homebody.png';
import { Link, useNavigate } from 'react-router-dom'
import { ShareContext } from '../context/sharecontext';
import * as Constants from '../Constants';
import LoadingImg from '../images/loading.gif';

import IdLayout from '../components/_layout';
import IdInput from '../components/idInput';
import IdSelect from '../components/idSelect';

function Users() {
    const { profile, token, setProfile } = useContext(ShareContext);
    const [staySigned, setStaySigned] = useState(false)
    //load profile info.
    const [agents, setAgents] =useState([]);
    const [fagents, setFAgents] =useState([]);
    const [loading, setLoading] =useState(false);
    const [agent, setAgent] = useState({id:0});
    const [view, setView] = useState('list');
    const [err, setErr] = useState('');
    const [transfer, setTransfer]=useState(0);
    const [tErr, setTErr] = useState('');
    const [searchAg, setSearchAg] =useState('');
    const [manualPay, setManualPay] = useState({});
    const [payments, setPayments] =useState([]);


    const channels =[{value:'', text:'Select Payment Channel'},{value:'paystack', text:'Paystack'},{value:'others', text:'Other Banks'}]
    const onSave=async(e)=>{
      e.preventDefault(); 

        setLoading(true);
        
        try {
          var v={...agent, status:'active', userType:'Agent'};
          const resp = await fetch(`${Constants.rooturl}/customer`, {
            method: 'POST',
            body:JSON.stringify(v) ,
            headers: {
              'Content-Type': 'application/json, text/plain',
              'Authorization': token,
            },
          });
          
          const data = await resp.json();
          setLoading(false);
          console.log(data);
          if (data.code === 0) {
             var ag={...agent, id:data.id};
            setAgent(ag);
            var ags=[...agents];
            ags.push(ags);
            setAgents(ags);
            setView('list');
           
          }else {   
            setErr('username/password not correct');
          }
        } catch (error) {
          setLoading(false);
          setErr(Constants.serverErr);
         // onMove();
        }

       }

    useEffect(() => {
       Load();
         }, []);
    
    const Load=async()=>{
      console.log(profile);
        var urls=`${Constants.rooturl}/customer/agents`;
        try {
            setLoading(true);
            const resp = await fetch(urls, { 
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': '',
                'Authorization': token,
              },
            });
            
            const data = await resp.json();
          
            setLoading(false);
            console.log(data);
            if (data.code === 0) {
                
                //setProfile(data.res);
                setAgents(data.agents);
                setFAgents(data.agents);
            }else {    
                console.log(data.status);
            }
          } catch (error) {
            setLoading(false);
            console.error(error); 
          }
     }
    const onNewAgent=()=>{
        setView('agent');
        setAgent({id:0});
    }
    const onToggleStatus=(a)=>{
        //save the status. call it on get is fine
        setAgent(a);
    }
    const onAddUnits=(a)=>{
        setView('transfer');
        setAgent(a);
    }
    const onAddPayment=(a)=>{
      setView('payment');
      setAgent(a);
  }
    const onCancel=()=>{
        setView('list');
    }

    const onSaveTransfer=async()=>{
        try {
            var v={agentId:agent.id, units:transfer};
            const resp = await fetch(`${Constants.rooturl}/customer/transfer`, {
              method: 'POST',
              body:JSON.stringify(v) ,
              headers: {
                'Content-Type': 'application/json, text/plain',
                'Authorization': token,
              },
            });
            
            const data = await resp.json();
            setLoading(false);
            console.log(data);
            if (data.code === 0) {
              Load();
              setView('list');
            }else {   
              setErr('username/password not correct');
            }
          } catch (error) {
            setLoading(false);
            setErr(Constants.serverErr);
           // onMove();
          }
    }

    const onSearchAg=(value)=>{
      if(!(profile?.userType=='Admin' || profile?.userType=='Auditor')){
          if(value!=''){
          const filteredData_ = agents?.filter(item => {
            const searchMatch = item.firstName.toLowerCase().includes(value.toLowerCase()) || (item.lastName?.toLowerCase().includes(value.toLowerCase())) || item.email.toLowerCase().includes(value.toLowerCase())
            
            return searchMatch 
        })
        setFAgents(filteredData_);
        }else{
          setFAgents(agents);
        }
      }else{
        //this will search online
      }
      
      
      setSearchAg(value);
  }
  const onServerSearch=async()=>{
 
      var urls=`${Constants.rooturl}/customer/searchuser/${searchAg}`;
      try {
          setLoading(true);
          const resp = await fetch(urls, { 
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': token,
              
            },
          });
          
          const data = await resp.json();
        
          setLoading(false);
          console.log(data);

          if (data.code === 0) {
            setAgents(data.agents);
                setFAgents(data.agents);
          }else {    
            console.error(data.status);
          }
        } catch (error) {
          setLoading(false);
          console.error(error); 
        }
   
  }
  const onManualPayment=async(e)=>{
    try {
      e.preventDefault(); 
        var v={userid:agent.id, ...manualPay};
        const resp = await fetch(`${Constants.rooturl}/customer/manualpayment`, {
          method: 'POST',
          body:JSON.stringify(v) ,
          headers: {
            'Content-Type': 'application/json, text/plain',
            'Authorization': token,
          },
        });
        
        const data = await resp.json();
        setLoading(false);
        console.log(data);
        if (data.code === 0) {
          alert('Payment saved successfully')
          Load();
          setView('list');
        }else {   
          setErr(data.status);
        }
      } catch (error) {
        setLoading(false);
        setErr(Constants.serverErr);
       // onMove();
      }
}

const LoadPayment=async(a)=>{
  
  setView('listpayments');
      setAgent(a);

    var urls=`${Constants.rooturl}/customer/payments/${a.id}`;
    try {
        setLoading(true);
        const resp = await fetch(urls, { 
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': '',
            'Authorization': token,
          },
        });
        
        const data = await resp.json();
      
        setLoading(false);
        console.log(data);
        if (data.code === 0) {
            
            //setProfile(data.res);
            setPayments(data.payments)
        }else {    
            console.log(data.status);
        }
      } catch (error) {
        setLoading(false);
        console.error(error); 
      }
 }

  return (
   <IdLayout active="users" profile={profile}>
    <div>
      {loading && <div>
        <img src={LoadingImg} style={{width:300, marginLeft:'auto', marginRight:'auto'}} />
        </div>}  
      {view=='list' && <div>
        <button className='greenbutton no-print' onClick={onNewAgent} >{profile?.userType=='Admin'?'New User':'New Agent'} </button>
        <div >
                            {/* <FontAwesomeIcon icon={faSearch} /> */}
                           <IdInput type="text" placeholder='Search Customer/Agent Name' value={searchAg} onChange={(e) =>onSearchAg(e.target.value) }/>
{(profile?.userType=='Admin' || profile?.userType=='Auditor') && <button className='greenbutton no-print' onClick={onServerSearch} >Search</button>}
                        </div>
            <table className='specialtable'>
            <tr>
                <th>Name</th>
                <th>Phone</th>
                <th>Email</th>
                <th>Balance</th>
                {profile?.userType=='Admin' && <th>User Type</th>}
                <th>Status</th>
                <th>Date Created</th>
                <th>Actions</th>
            </tr>
        {fagents?.map((agent, idx) =>{return (
         <tr>
            <td>{agent.firstName} {agent.lastName}</td>
            <td>{agent.phone}</td>
            <td>{agent.email}</td>
            <td>{agent.unitBalance}</td>
            {profile?.userType=='Admin' && <td>{agent.userType}</td>}
            <td>{agent.status}</td>
            <td>{Constants.formatDate(agent.createDate) }</td>
            <td><button onClick={()=>onToggleStatus(agent)}>{agent.status=='active'? 'Disable' :'Enable'}</button>
            <button onClick={()=>onAddUnits(agent)}>Add Unit(s)</button>
           {profile?.userType=='Admin' && 
           <>
           <button onClick={()=>onAddPayment(agent)}>Add Payment</button>
           <button onClick={()=>LoadPayment(agent)}>View Payments</button>
           </> } 
            </td>
            
         </tr>
        )})}
   </table>
        </div>} 
      {view=='agent' && <div>
        <div style={{display:'flex'}}>
            <div style={{textAlign:'right'}}><button onClick={()=> setView('list')}>Close</button></div>
            <div style={{width:500, maxWidth:'80%', margin:'auto'}}>
               <h1>New Agent Details</h1>
                <form  onSubmit={onSave}>
                    <div style={{display:'flex'}}>
                    <div style={{marginRight:10}}><IdInput label="First Name"
                     value={agent.firstname} onChange={(e)=>setAgent({...agent,firstname: e.target.value})} required /></div>
                    <div style={{marginLeft:10}}><IdInput label="Last Name"
                    value={agent.lastname} onChange={(e)=>setAgent({...agent,lastname: e.target.value})}  /></div>
                </div>
               <IdInput label="Email" value={agent.email}  onChange={(e)=>setAgent({...agent,email: e.target.value})} />
              
             {agent.id==0 && <div style={{display:'flex'}}>
                    <div style={{marginRight:10}}> <IdInput label="Password" type="password" 
                    value={agent.password} onChange={(e)=>setAgent({...agent,password: e.target.value})}  /></div>
                   <div style={{marginRight:10}}> <IdInput label="Start Balance" type="number" 
                    value={agent.unitBalance} min={0} onChange={(e)=>setAgent({...agent,unitBalance: e.target.value})}  /></div>
                </div>}  

               {loading && <div  style={{display:'flex', justifyContent:'center', marginBottom:16, flexDirection:'column'}}>
                                  <img src={LoadingImg} style={{width:300, marginLeft:'auto', marginRight:'auto'}} />
                                  <span style={{ marginLeft:'auto', marginRight:'auto'}}>Loading...</span>
                                </div>}  
                    {!loading && <div style={{display:'flex', justifyContent:'center', marginBottom:16}}>
                                    <div style={{marginTop:16, marginBottom:16}}>
                                        <button className='greenbutton' style={{width:'100%'}}>Save</button>
                                        </div>          
                              </div>} 
                </form>
                
                              <div style={{fontSize:15, color:'red', textAlign:'center', padding:15, fontWeight:'bold'}}>{err}</div>
         
            </div>
       </div>  
        </div>}  
      {view=='transfer' && <div>
        <div style={{display:'flex', backgroundColor:'white', flex:1, flexDirection:'column'}}>


         <p>  </p>
         <form onSubmit={onSaveTransfer}>
         <IdInput label="Enter Number of units to buy:" type='number' min={agent.unitBalance*-1} value={transfer} onChange={(e)=>setTransfer(e.target.value)} required/>
<div className="success-box">
         <h2>Currently {agent.firtName} {agent.lastName} has {agent.unitBalance}units. You are currently transfering {transfer}</h2>
         <p style={{color:'red'}}>Note: The negative value means deduction to your account, while + means you are adding units</p>
             <strong style={{marginTop:16, color:'red'}}>{tErr}</strong>
           <button className='greenbutton'  type='submit'>Transfer</button>  <button className='red' onClick={onCancel} type='button'>Cancel</button> 
       </div>     
         </form>  
    

    <div className='ninsearch'>
         <div className='cdiv'>
            
             <div> <span></span> <span></span> </div>
         </div>
         
    </div>
      </div>
        </div>}
        {view=='payment' && <div>
        <div style={{display:'flex', backgroundColor:'white', flex:1, flexDirection:'column'}}>


         <p>  </p>
         <form onSubmit={onManualPayment}>
          
         
          <div style={{display:'flex', flexDirection:'row'}}> 
            <IdInput label="Transaction ID" type='text' maxLength={50} value={manualPay.refid} onChange={(e)=>setManualPay({...manualPay,refid:e.target.value})} required/>
            <IdInput label="Amount Paid" type='number' min={0} value={manualPay.amount} onChange={(e)=>setManualPay({...manualPay,amount:e.target.value})} required/>
          </div>
          <div style={{display:'flex', flexDirection:'row'}}>
          <IdSelect onChange={(e)=>setManualPay({...manualPay,payChannel:e.target.value})}value={manualPay.payChannel} disabledText="Select Channel" items={channels} label='Payment Channel' required />
          <IdInput label="Transaction Date" type='date' min={0} value={manualPay.transDate} onChange={(e)=>setManualPay({...manualPay,transDate:e.target.value})} required/>  
          </div>

<div className="success-box">
         <h2>Note {agent.firtName} {agent.lastName} has {agent.unitBalance}units. </h2>
         
             <strong style={{marginTop:16, color:'red'}}>{tErr}</strong>
           <button className='greenbutton'  type='submit'>Save Payment</button>  <button className='red' onClick={onCancel} type='button'>Cancel</button> 
       </div>     
         </form>  
    

    <div className='ninsearch'>
         <div className='cdiv'>
            
             <div> <span></span> <span></span> </div>
         </div>
         
    </div>
      </div>
        </div>}
        {view=='paymentlist' && <div>
          <table className='specialtable'> 
    <tr><th>Reference ID</th><th>Amount</th><th>Units</th><th>Date</th><th>Payment Channel</th></tr>
    {payments?.map((pay, idx) =>{return (
         <tr>
            <td>{pay.payRef}</td>
            <td>{pay.amount}</td>
            <td>{pay.balanceUnit}</td>
            <td>{Constants.formatDate(pay.createDate) } {pay.transDate!=null && <strong>**Trans Date {Constants.formatDate(pay.transDate)}</strong>} </td>
            <td>{pay.channel}</td>
         </tr>
     )})}
   </table>
   <button className='red' onClick={onCancel} type='button'>Cancel</button> 
          </div>}
    </div>
   </IdLayout>
  )
}

export default Users